var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-2" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end", attrs: { cols: "10" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: !_vm.selectedMachine.length },
                  on: {
                    click: function ($event) {
                      return _vm.openEditDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("machinesTab.editBtn")) + " ")]
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.machines,
                  loading: _vm.loading,
                  "item-key": "id",
                  "show-select": "",
                  "single-select": "",
                },
                model: {
                  value: _vm.selectedMachine,
                  callback: function ($$v) {
                    _vm.selectedMachine = $$v
                  },
                  expression: "selectedMachine",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _c(
            "v-form",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h6 white--text primary" },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("machinesTab.editMachines.title")) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-4" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  disabled: "",
                                  label: _vm.$t(
                                    "machinesTab.editMachines.name"
                                  ),
                                },
                                model: {
                                  value: _vm.currentMachine.machine_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentMachine,
                                      "machine_name",
                                      $$v
                                    )
                                  },
                                  expression: "currentMachine.machine_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-4" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "machinesTab.editMachines.description"
                                  ),
                                },
                                model: {
                                  value: _vm.currentMachine.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentMachine,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "currentMachine.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-4" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.locations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "menu-props": "auto",
                                  label: _vm.$t(
                                    "machinesTab.editMachines.customerLocation"
                                  ),
                                  "hide-details": "",
                                  "single-line": "",
                                },
                                model: {
                                  value:
                                    _vm.currentMachine.customer_location_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentMachine,
                                      "customer_location_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentMachine.customer_location_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-1",
                              attrs: { color: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateMachine()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("machinesTab.editMachines.save")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-1",
                              on: {
                                click: function ($event) {
                                  return _vm.cancel()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("machinesTab.editMachines.cancel")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackBarTimeout, color: "secondary" },
          model: {
            value: _vm.saveFinished,
            callback: function ($$v) {
              _vm.saveFinished = $$v
            },
            expression: "saveFinished",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("machinesTab.saveFinished")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackBarTimeout, color: "error" },
          model: {
            value: _vm.saveError,
            callback: function ($$v) {
              _vm.saveError = $$v
            },
            expression: "saveError",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("machinesTab.saveError")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }