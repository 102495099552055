var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [_vm._v(" " + _vm._s(_vm.$t("accountPage.deleteTitle")) + " ")]
              ),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.question) + " "),
                _vm.error
                  ? _c("span", { staticClass: "error--text" }, [
                      _vm._v(" " + _vm._s(_vm.error) + " "),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.error === ""
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary white--text v-btn" },
                          on: { click: _vm.deleteAccount },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("globalActions.delete")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "gray darken-3" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("globalActions.cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }