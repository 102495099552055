var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "tabBackground" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("tagsTab.search"),
                  color: "grey darken-1",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("tagsTab.addBtn")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: _vm.selectedTag.length === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.openDeleteDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("tagsTab.deleteBtn")) + " ")]
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.tags,
                  search: _vm.searchText,
                  loading: _vm.loading,
                  "item-key": "id",
                  "show-select": "",
                  "single-select": "",
                },
                model: {
                  value: _vm.selectedTag,
                  callback: function ($$v) {
                    _vm.selectedTag = $$v
                  },
                  expression: "selectedTag",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "400px" },
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [_vm._v(" " + _vm._s(_vm.$t("tagsTab.deleteTag.title")) + " ")]
              ),
              _c(
                "v-card-text",
                [
                  _vm.deleteDialog && _vm.selectedTag[0].capture_count
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "div",
                              { staticClass: "text-h6 font-weight-bold my-4" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedTag[0].capture_count +
                                        _vm.$t(
                                          "tagsTab.deleteTag.confirmHasCaptures"
                                        )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("div", { staticClass: "text-h6 my-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("tagsTab.deleteTag.confirmText")) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteTag()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("tagsTab.deleteTag.delete")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancelDelete()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("tagsTab.deleteTag.cancel")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.addDialog,
            callback: function ($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog",
          },
        },
        [
          _c(
            "v-form",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h6 white--text primary" },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("tagsTab.addTag.title")) + " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: _vm.$t("tagsTab.addTag.name"),
                                },
                                model: {
                                  value: _vm.newTag.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newTag, "name", $$v)
                                  },
                                  expression: "newTag.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.addTag()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("tagsTab.addTag.add")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("tagsTab.addTag.cancel")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.addSuccessSnackbar,
            callback: function ($$v) {
              _vm.addSuccessSnackbar = $$v
            },
            expression: "addSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("tagsTab.addTag.success")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.addErrorSnackbar,
            callback: function ($$v) {
              _vm.addErrorSnackbar = $$v
            },
            expression: "addErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("tagsTab.addTag.error")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.deleteSuccessSnackbar,
            callback: function ($$v) {
              _vm.deleteSuccessSnackbar = $$v
            },
            expression: "deleteSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("tagsTab.deleteTag.success")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.deleteErrorSnackbar,
            callback: function ($$v) {
              _vm.deleteErrorSnackbar = $$v
            },
            expression: "deleteErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("tagsTab.deleteTag.error")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }