// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountDetails_title_i7MVt {\n  background-color: #1976D2;\n  color: #FFFFFF;\n}\n.AccountDetails_form_OF9tN {\n  padding: 0px 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/Account/AccountDetails.vue","webpack://./src/css/variables.scss"],"names":[],"mappings":"AAuGA;EACE,yBCvFa;EDwFb,cAAA;AAtGF;AAyGA;EACE,iBAAA;AAtGF","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n@import '../../css/variables';\n\n.title {\n  background-color: $blue-title-2;\n  color: #FFFFFF;\n}\n\n.form {\n  padding: 0px 20px;\n}\n","// Colors\n$orange-text: #E65100;\n$gray-text: #757575;\n$grey-lighten-3: #EEEEEE;\n$grey-lighten-2: #E0E0E0;\n$grey-lighten-1: #F5F5F5;\n$grey-darken-1: #757575;\n$grey-darken-2: #333333;\n$grey-darken-3: #C4C4C4;\n$grey-1: #E3E3E3;\n$grey-2: #DCDCDC;\n$grey-3: #F2F2F2;\n$grey-4: #CCCCCC;\n$grey-5: #E5E5E5;\n$grey-6: #FAFAFA;\n$black-text: #1B1B1B;\n$blue-title: #17607D;\n$blue-title-2: #1976D2;\n\n//Width\n$container-width: 1200px;\n$container-padding: 10px 0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "AccountDetails_title_i7MVt",
	"form": "AccountDetails_form_OF9tN"
};
export default ___CSS_LOADER_EXPORT___;
