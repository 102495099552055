var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-12", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticClass: "subHeader" }, [
              _vm._v(" " + _vm._s(_vm.$t("capturesPage.title")) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.tags,
                  "item-text": "name",
                  "item-value": "id",
                  color: "secondary",
                  chips: "",
                  label: _vm.$t("capturesPage.filterTags"),
                  multiple: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (data) {
                      return [
                        _c(
                          "v-chip",
                          {
                            key: JSON.stringify(data.item.id),
                            staticClass: "secondary",
                            attrs: { color: "white", close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeTag(data.item.id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(data.item.name) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.searchTags,
                  callback: function ($$v) {
                    _vm.searchTags = $$v
                  },
                  expression: "searchTags",
                },
              }),
            ],
            1
          ),
          _vm.isAdmin
            ? _c(
                "v-col",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.users,
                      "item-text": "username",
                      "item-value": "id",
                      color: "secondary",
                      chips: "",
                      label: _vm.$t("capturesPage.filterUsers"),
                      multiple: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  key: JSON.stringify(data.item.id),
                                  staticClass: "secondary",
                                  attrs: { color: "white", close: "" },
                                  on: {
                                    "click:close": function ($event) {
                                      return _vm.removeUser(data.item.id)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(data.item.username) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1437776352
                    ),
                    model: {
                      value: _vm.searchUsers,
                      callback: function ($$v) {
                        _vm.searchUsers = $$v
                      },
                      expression: "searchUsers",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isCloudEnv
            ? _c(
                "v-col",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.machines,
                      "item-text": "machine_name",
                      "item-value": "id",
                      color: "secondary",
                      chips: "",
                      label: _vm.$t("capturesPage.filterMachines"),
                      multiple: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  key: JSON.stringify(data.item.id),
                                  staticClass: "secondary",
                                  attrs: { color: "white", close: "" },
                                  on: {
                                    "click:close": function ($event) {
                                      return _vm.removeMachine(data.item.id)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.machine_name) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      897221887
                    ),
                    model: {
                      value: _vm.searchMachines,
                      callback: function ($$v) {
                        _vm.searchMachines = $$v
                      },
                      expression: "searchMachines",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isCloudEnv
            ? _c(
                "v-col",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.locations,
                      "item-text": "name",
                      "item-value": "id",
                      color: "secondary",
                      chips: "",
                      label: _vm.$t("capturesPage.filterLocations"),
                      multiple: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  key: JSON.stringify(data.item.id),
                                  staticClass: "secondary",
                                  attrs: { color: "white", close: "" },
                                  on: {
                                    "click:close": function ($event) {
                                      return _vm.removeLocation(data.item.id)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(data.item.name) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2511394429
                    ),
                    model: {
                      value: _vm.searchLocations,
                      callback: function ($$v) {
                        _vm.searchLocations = $$v
                      },
                      expression: "searchLocations",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-right": 40,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                staticClass: "mt-3",
                                attrs: {
                                  label: _vm.$t("capturesPage.filterStartDate"),
                                  readonly: "",
                                  clearable: "",
                                  "persistent-hint": "",
                                  "append-icon": "event",
                                },
                                model: {
                                  value: _vm.startDateDisp,
                                  callback: function ($$v) {
                                    _vm.startDateDisp = $$v
                                  },
                                  expression: "startDateDisp",
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.startDatePicker,
                    callback: function ($$v) {
                      _vm.startDatePicker = $$v
                    },
                    expression: "startDatePicker",
                  },
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      "no-title": "",
                      max: _vm.endDate !== "" ? _vm.endDate : _vm.currentDate,
                    },
                    on: {
                      input: function ($event) {
                        _vm.startDatePicker = false
                      },
                    },
                    model: {
                      value: _vm.startDate,
                      callback: function ($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "nudge-right": 40,
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                staticClass: "mt-3",
                                attrs: {
                                  label: _vm.$t("capturesPage.filterEndDate"),
                                  readonly: "",
                                  clearable: "",
                                  "persistent-hint": "",
                                  "append-icon": "event",
                                },
                                model: {
                                  value: _vm.endDateDisp,
                                  callback: function ($$v) {
                                    _vm.endDateDisp = $$v
                                  },
                                  expression: "endDateDisp",
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.endDatePicker,
                    callback: function ($$v) {
                      _vm.endDatePicker = $$v
                    },
                    expression: "endDatePicker",
                  },
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      "no-title": "",
                      min: _vm.startDate !== "" ? _vm.startDate : undefined,
                      max: _vm.currentDate,
                    },
                    on: {
                      input: function ($event) {
                        _vm.endDatePicker = false
                      },
                    },
                    model: {
                      value: _vm.endDate,
                      callback: function ($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        _vm._l(_vm.captures, function (capture, index) {
          return _c(
            "v-col",
            { key: index, attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                {
                  attrs: { "max-width": "375", ripple: false },
                  on: {
                    click: function ($event) {
                      return _vm.gotoCapture(capture.id)
                    },
                  },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-click": "",
                        "offset-y": "",
                        "offset-x": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "my-2",
                                      class: _vm.$style.btn,
                                      attrs: {
                                        color: "white",
                                        tile: "",
                                        icon: "",
                                        small: "",
                                        absolute: "",
                                        right: "",
                                        ripple: false,
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { large: "" } }, [
                                      _vm._v(" more_vert "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.gotoAccount(capture)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("capturesPage.item.viewAccount")
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          capture.compression_type !== 2
                            ? _c(
                                "v-list-group",
                                {
                                  attrs: { "no-action": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "capturesPage.item.addPresets"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                _vm._l(_vm.presetProfiles, function (profile) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: profile.id,
                                      staticClass: "pl-6",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addPresets(
                                            capture,
                                            profile
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(profile.name),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          capture.compression_type !== 2
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVideo(capture)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("capturesPage.item.addVideo")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  capture.thumbnail_url !== undefined ||
                  capture.thumbnail_url !== ""
                    ? _c(
                        "v-img",
                        {
                          staticClass: "white--text align-end",
                          class: _vm.$style.roundedImg,
                          attrs: {
                            src: _vm.getCaptureImage(index),
                            "aspect-ratio": "1.5",
                          },
                        },
                        [
                          _c("v-card-title", [
                            _vm._v(" " + _vm._s(capture.account_name) + " "),
                          ]),
                        ],
                        1
                      )
                    : _c("v-icon", [_vm._v(" account_box ")]),
                  _c(
                    "v-card-text",
                    { staticClass: "text--primary" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "11" } }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.getCaptureCreateTime(index))
                                  ),
                                ]),
                                capture.uploaded_files >= capture.total_files
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(capture.customer_location) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                capture.uploaded_files >= capture.total_files
                                  ? _c("div", [
                                      _vm._v(
                                        " " + _vm._s(capture.machine_name) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                capture.uploaded_files < capture.total_files
                                  ? _c(
                                      "div",
                                      { staticClass: "secondary--text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("capturesPage.uploading", [
                                                capture.uploaded_files,
                                                capture.total_files,
                                              ])
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                capture.uploaded_files < capture.total_files
                                  ? _c("div")
                                  : _vm._e(),
                              ]),
                              capture.audio_file
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-end py-0",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v(" audiotrack ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span"),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("v-pagination", {
        staticClass: "my-4",
        attrs: { length: _vm.captureCount, "total-visible": 7 },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }