var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.withLayout ? _c("SidePanel", { ref: "sidePanel" }) : _vm._e(),
      _vm.withLayout
        ? _c("HeaderToolBar", { on: { "toggle-navbar": _vm.toggleNavbar } })
        : _vm._e(),
      _vm.withLayout
        ? _c("v-main", [_c("router-view")], 1)
        : _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }