var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "tabBackground" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-card-title",
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: _vm.$t("userSettingsTab.search"),
                      color: "grey darken-1",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.users,
                  search: _vm.searchText,
                  loading: _vm.loading,
                  "item-key": "id",
                  "show-select": "",
                  "single-select": "",
                  "selectable-key": "isSelectable",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.active",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("v-switch", {
                            attrs: {
                              disabled: !_vm.canActivateUser(item),
                              label: item.active
                                ? _vm.$t("userSettingsTab.table.active")
                                : _vm.$t("userSettingsTab.table.inactive"),
                              color: "secondary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showDeactivateUserPopup(item)
                              },
                            },
                            model: {
                              value: item.active,
                              callback: function ($$v) {
                                _vm.$set(item, "active", $$v)
                              },
                              expression: "item.active",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "item.access_level",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getPermissionName(item.access_level)
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedUserId,
                  callback: function ($$v) {
                    _vm.selectedUserId = $$v
                  },
                  expression: "selectedUserId",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary white--text mx-2",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showUserPopup(true)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("userSettingsTab.add")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "secondary white--text mx-2",
                  attrs: {
                    disabled:
                      _vm.selectedUserId[0] === undefined ||
                      _vm.user.access_level > _vm.loggedUser.access_level,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showUserPopup(false)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("userSettingsTab.edit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c("ValidationObserver", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var invalid = ref.invalid
                  var handleSubmit = ref.handleSubmit
                  return [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "text-h6 white--text primary" },
                          [
                            _c("span", { staticClass: "text-h5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.id
                                      ? _vm.$t("userSettingsTab.editUser.title")
                                      : _vm.$t("userSettingsTab.addUser.title")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "username",
                                        rules: "required|max:20|alpha_num",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              var valid = ref.valid
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages": errors,
                                                    success: valid,
                                                    label: _vm.$t(
                                                      "userSettingsTab.username"
                                                    ),
                                                    color: "secondary",
                                                  },
                                                  model: {
                                                    value: _vm.user.username,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "username",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.username",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "email",
                                        rules: "required|email",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              var valid = ref.valid
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages": errors,
                                                    success: valid,
                                                    label: _vm.$t(
                                                      "userSettingsTab.email"
                                                    ),
                                                    color: "secondary",
                                                  },
                                                  model: {
                                                    value: _vm.user.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "email",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.email",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _vm.canSetPassword
                                  ? _c(
                                      "v-col",
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "password",
                                            rules:
                                              "min:6|max:100|confirmed:password_confirmation",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "error-messages":
                                                          errors,
                                                        success: valid,
                                                        label: _vm.$t(
                                                          "userSettingsTab.password"
                                                        ),
                                                        type: "password",
                                                        color: "secondary",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.user.password,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.user,
                                                            "password",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "user.password",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _vm.canSetPassword
                                  ? _c(
                                      "v-col",
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "password_confirmation",
                                            vid: "password_confirmation",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "error-messages":
                                                          errors,
                                                        success: valid,
                                                        label: _vm.$t(
                                                          "userSettingsTab.confirmPassword"
                                                        ),
                                                        type: "password",
                                                        color: "secondary",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.user
                                                            .password_confirmation,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.user,
                                                            "password_confirmation",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "user.password_confirmation",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        rules: "required",
                                        name: "access_level",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "userSettingsTab.permissionLevel"
                                                      )
                                                    ) + "*"
                                                  ),
                                                ]),
                                                _c(
                                                  "v-btn-toggle",
                                                  {
                                                    attrs: {
                                                      "error-messages": errors,
                                                      name: "access_level",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.access_level,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "access_level",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.access_level",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.visibleAccessLevels,
                                                    function (permission) {
                                                      return _c(
                                                        "v-btn",
                                                        {
                                                          key: permission.accessLevel,
                                                          staticClass:
                                                            "text-caption",
                                                          attrs: {
                                                            value:
                                                              permission.accessLevel,
                                                            color:
                                                              "secondary white--text v-btn",
                                                            text: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                permission.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: {
                                  color: "secondary white--text",
                                  disabled: invalid,
                                },
                                on: {
                                  click: function ($event) {
                                    return handleSubmit(_vm.addUser)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.user.id
                                        ? _vm.$t("userSettingsTab.editUser.ok")
                                        : _vm.$t("userSettingsTab.addUser.ok")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: { color: "gray darken-3" },
                                on: {
                                  click: function ($event) {
                                    _vm.dialog = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("userSettingsTab.addUser.cancel")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "400" },
          model: {
            value: _vm.dialogDeactivateUser,
            callback: function ($$v) {
              _vm.dialogDeactivateUser = $$v
            },
            expression: "dialogDeactivateUser",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.currentActivateUser &&
                          !_vm.currentActivateUser.active
                          ? _vm.$t("userSettingsTab.deactivateUser.title")
                          : _vm.$t("userSettingsTab.activateUser.title")
                      ) +
                      " "
                  ),
                ]
              ),
              _c("v-card-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.currentActivateUser && !_vm.currentActivateUser.active
                        ? _vm.$t("userSettingsTab.deactivateUser.message")
                        : _vm.$t("userSettingsTab.activateUser.message")
                    ) +
                    " "
                ),
                _c("br"),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary white--text v-btn" },
                      on: {
                        click: function ($event) {
                          return _vm.activateUser()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.currentActivateUser &&
                              !_vm.currentActivateUser.active
                              ? _vm.$t("userSettingsTab.deactivateUser.submit")
                              : _vm.$t("userSettingsTab.activateUser.submit")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "gray darken-3" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelActivation()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("userSettingsTab.activateUser.cancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.errorSnackBar.show,
            callback: function ($$v) {
              _vm.$set(_vm.errorSnackBar, "show", $$v)
            },
            expression: "errorSnackBar.show",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.errorSnackBar.message) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.successSnackbar.show,
            callback: function ($$v) {
              _vm.$set(_vm.successSnackbar, "show", $$v)
            },
            expression: "successSnackbar.show",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.successSnackbar.message) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }