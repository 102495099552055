var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-row", {
        staticClass: "flex",
        attrs: { align: "center", justify: "center" },
      }),
      _c(
        "v-row",
        { staticClass: "flex", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pwd-reset-form", attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "6" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "flex",
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center justify-center",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("img", {
                            staticClass: "image-pwd-reset",
                            attrs: { src: require("@/assets/logo.png") },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pt-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-form",
                          {
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                _vm.valid && _vm.submit($event)
                              },
                            },
                            model: {
                              value: _vm.valid,
                              callback: function ($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid",
                            },
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [_vm.passwordRules.required],
                                type: "password",
                                label: _vm.$t(
                                  "passwordResetPage.enterPassword"
                                ),
                              },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  _vm.passwordRules.required,
                                  _vm.passwordRules.emailMatch,
                                ],
                                type: "password",
                                label: _vm.$t(
                                  "passwordResetPage.confirmPassword"
                                ),
                              },
                              model: {
                                value: _vm.passwordConfirm,
                                callback: function ($$v) {
                                  _vm.passwordConfirm = $$v
                                },
                                expression: "passwordConfirm",
                              },
                            }),
                            _c(
                              "v-row",
                              {
                                staticClass: "error-pwd-reset",
                                attrs: { justify: "center" },
                              },
                              [
                                _vm.error
                                  ? _c(
                                      "label",
                                      { staticClass: "error--text" },
                                      [_vm._v(" " + _vm._s(_vm.error) + " ")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "v-row",
                              { attrs: { justify: "center" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "secondary white--text",
                                    attrs: { disabled: !_vm.valid },
                                    on: { click: _vm.submit },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("passwordResetPage.submit")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }