var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-2" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end", attrs: { cols: "10" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddDialog(true)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("accountDocumentFieldsTab.addBtn")) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: !_vm.selectedField.length },
                  on: {
                    click: function ($event) {
                      return _vm.openAddDialog(false)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("accountDocumentFieldsTab.editBtn")) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: !_vm.selectedField.length },
                  on: {
                    click: function ($event) {
                      return _vm.openDeleteDialog()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("accountDocumentFieldsTab.deleteBtn")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.fields,
                  loading: _vm.loading,
                  "item-key": "id",
                  "show-select": "",
                  "single-select": "",
                },
                model: {
                  value: _vm.selectedField,
                  callback: function ($$v) {
                    _vm.selectedField = $$v
                  },
                  expression: "selectedField",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-form",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h6 white--text primary" },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.addMode
                                ? _vm.$t(
                                    "accountDocumentFieldsTab.addField.title"
                                  )
                                : _vm.$t(
                                    "accountDocumentFieldsTab.editField.title"
                                  )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: _vm.$t(
                                    "accountDocumentFieldsTab.addField.key"
                                  ),
                                },
                                model: {
                                  value: _vm.newField.key,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newField, "key", $$v)
                                  },
                                  expression: "newField.key",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: _vm.$t(
                                    "accountDocumentFieldsTab.addField.description"
                                  ),
                                },
                                model: {
                                  value: _vm.newField.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newField, "description", $$v)
                                  },
                                  expression: "newField.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "secondary",
                                  label: _vm.$t(
                                    "accountDocumentFieldsTab.addField.visible"
                                  ),
                                },
                                model: {
                                  value: _vm.newField.visible,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newField, "visible", $$v)
                                  },
                                  expression: "newField.visible",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitField()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.addMode
                                  ? _vm.$t(
                                      "accountDocumentFieldsTab.addField.add"
                                    )
                                  : _vm.$t(
                                      "accountDocumentFieldsTab.editField.save"
                                    )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "accountDocumentFieldsTab.addField.cancel"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [
                  _c("span", { staticClass: "text-h5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("accountDocumentFieldsTab.delete.title")
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("span", { staticClass: "text-h5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "accountDocumentFieldsTab.delete.question"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.error
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", { staticClass: "text-h5" }, [
                              _vm._v(" " + _vm._s(_vm.error) + " "),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteField()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("accountDocumentFieldsTab.delete.ok")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("accountDocumentFieldsTab.delete.cancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }