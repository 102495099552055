var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-navigation-drawer",
        { attrs: { app: "", clipped: "", right: "", width: "300" } },
        [
          _c("h2", { staticClass: "pl-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("videoStudio.videoComparePlaylists.sideBar.title")
                ) +
                " "
            ),
          ]),
          _c(
            "v-list",
            [
              _c(
                "v-list-item-content",
                { staticClass: "pl-4" },
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "videoStudio.videoComparePlaylists.sideBar.player1"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "draggable",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { list: _vm.playlist1, group: "videos" },
                  on: { end: _vm.dragEnded },
                },
                _vm._l(_vm.playlist1, function (item) {
                  return _c(
                    "v-list-item",
                    { key: item.captureId },
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { "min-height": "50px" } },
                        [
                          _c("v-img", {
                            staticClass: "white--text align-end",
                            attrs: { src: item.preview },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.account_name + " - " + item.captureId
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getCaptureCreateTime(item.date_created)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                top: "",
                                "close-on-click": "",
                                "offset-y": "",
                                "offset-x": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g({ attrs: { icon: "" } }, on),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey lighten-1",
                                                },
                                              },
                                              [_vm._v(" more_vert ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { color: "grey lighten-3" } },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.movePlaylist1ToPlaylist2(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.movePlayer2"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFromPlaylist1(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.removePlaying"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFromStudio(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.removeStudio"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-list",
            [
              _c(
                "v-list-item-content",
                { staticClass: "pl-4" },
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "videoStudio.videoComparePlaylists.sideBar.player2"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "draggable",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { list: _vm.playlist2, group: "videos" },
                  on: { end: _vm.dragEnded },
                },
                _vm._l(_vm.playlist2, function (item) {
                  return _c(
                    "v-list-item",
                    { key: item.captureId },
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { "min-height": "50px" } },
                        [
                          _c("v-img", {
                            staticClass: "white--text align-end",
                            attrs: { src: item.preview },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.account_name + " - " + item.captureId
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getCaptureCreateTime(item.date_created)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                top: "",
                                "close-on-click": "",
                                "offset-y": "",
                                "offset-x": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g({ attrs: { icon: "" } }, on),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey lighten-1",
                                                },
                                              },
                                              [_vm._v(" more_vert ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { color: "grey lighten-3" } },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.movePlaylist2ToPlaylist1(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.movePlayer1"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFromPlaylist2(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.removePlaying"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFromStudio(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.removeStudio"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-list",
            [
              _c(
                "v-list-item-content",
                { staticClass: "pl-4" },
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "videoStudio.videoComparePlaylists.sideBar.inactiveVideos"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "draggable",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { list: _vm.unselectedVideos, group: "videos" },
                  on: { end: _vm.dragEnded },
                },
                _vm._l(_vm.unselectedVideos, function (item) {
                  return _c(
                    "v-list-item",
                    { key: item.captureId },
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { "min-height": "50px" } },
                        [
                          _c("v-img", {
                            staticClass: "white--text align-end",
                            attrs: { src: item.preview },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.account_name + " - " + item.captureId
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                top: "",
                                "close-on-click": "",
                                "offset-y": "",
                                "offset-x": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g({ attrs: { icon: "" } }, on),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey lighten-1",
                                                },
                                              },
                                              [_vm._v(" more_vert ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { color: "grey lighten-3" } },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.addToPlaylist1(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.addPlayer1"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.addToPlaylist2(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.addPlayer2"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFromStudio(
                                                item.captureId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "videoStudio.videoComparePlaylists.sideBar.removeStudio"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("VideoPlaylistPlayer", {
        attrs: {
          playlists: _vm.playlists,
          "show-date": _vm.showDate,
          "show-timestamp": _vm.showTimestamp,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }