var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 px-12" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticClass: "subHeader" }, [
              _vm._v(" " + _vm._s(_vm.$t("imageStudioReviewPage.title")) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "10" } }),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  on: {
                    click: function ($event) {
                      _vm.infoDialog = true
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("imageStudioReviewPage.info")) + " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadProject()
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("imageStudioReviewPage.download")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col"),
          _c(
            "v-col",
            _vm._l(_vm.project.page_count, function (index) {
              return _c(
                "v-img",
                {
                  key: index,
                  staticClass: "mb-5",
                  attrs: {
                    "max-width": _vm.computePageDimensions(),
                    src: _vm.getPageUrl(index),
                  },
                },
                [
                  _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var hover = ref.hover
                            return [
                              _c("v-expand-transition", [
                                _c(
                                  "div",
                                  { staticStyle: { height: "100%" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hover,
                                            expression: "hover",
                                          },
                                        ],
                                        attrs: {
                                          icon: "",
                                          "x-large": "",
                                          color: "white",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadImage(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(" cloud_download "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c("v-col"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.infoDialog,
            callback: function ($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("imageStudioReviewPage.infoTitle")) +
                      " "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { color: "primary" } },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.project.captures, function (capture, idx) {
                          return _c(
                            "v-list-item",
                            {
                              key: idx,
                              on: {
                                click: function ($event) {
                                  return _vm.gotoCapture(idx)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.captureInfo(idx)) + " "
                                      ),
                                      _c("v-list-item-title"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }