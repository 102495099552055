import { render, staticRenderFns } from "./Captures.vue?vue&type=template&id=62b6f5a7&scoped=true&"
import script from "./Captures.vue?vue&type=script&lang=js&"
export * from "./Captures.vue?vue&type=script&lang=js&"
import style0 from "./Captures.vue?vue&type=style&index=0&id=62b6f5a7&lang=scss&module=true&"
import style1 from "./Captures.vue?vue&type=style&index=1&id=62b6f5a7&lang=scss&scoped=true&"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$style"] = (style0.locals || style0)
        Object.defineProperty(this, "$style", {
          configurable: true,
          get: function () {
            return cssModules["$style"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["./Captures.vue?vue&type=style&index=0&id=62b6f5a7&lang=scss&module=true&"], function () {
          var oldLocals = cssModules["$style"]
          if (oldLocals) {
            var newLocals = require("./Captures.vue?vue&type=style&index=0&id=62b6f5a7&lang=scss&module=true&")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$style"] = newLocals
              require("/workspace/app/node_modules/vue-hot-reload-api/dist/index.js").rerender("62b6f5a7")
            }
          }
        })

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "62b6f5a7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62b6f5a7')) {
      api.createRecord('62b6f5a7', component.options)
    } else {
      api.reload('62b6f5a7', component.options)
    }
    module.hot.accept("./Captures.vue?vue&type=template&id=62b6f5a7&scoped=true&", function () {
      api.rerender('62b6f5a7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Captures.vue"
export default component.exports