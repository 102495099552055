var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.videos.length > 0
    ? _c(
        "v-container",
        { staticClass: "px-0 mx-3" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "justify-center text-h5" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("accountPage.videoStudio.title")) + " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            _vm._l(_vm.videos, function (video, index) {
              return _c(
                "v-col",
                { key: index, attrs: { cols: "4" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: { ripple: false },
                      on: {
                        click: function ($event) {
                          return _vm.gotoVideoStudioProject(video.id)
                        },
                      },
                    },
                    [
                      _vm.isAdminUser()
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-click": "",
                                "offset-y": "",
                                "offset-x": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "my-2 cardMenuBtn",
                                              attrs: {
                                                color: "white",
                                                tile: "",
                                                icon: "",
                                                small: "",
                                                absolute: "",
                                                right: "",
                                                ripple: false,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { large: "" } },
                                              [_vm._v(" more_vert ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDeleteDialog(video.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "accountPage.videoStudio.deleteProject"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      video.thumbnail
                        ? _c("v-img", {
                            staticClass: "white--text align-end",
                            class: _vm.$style.roundedImg,
                            attrs: {
                              src: _vm.getVideoThumbnail(video.id),
                              "aspect-ratio": "1.5",
                            },
                          })
                        : _c(
                            "v-img",
                            {
                              staticClass: "d-flex justify-center align-center",
                              attrs: {
                                width: "300",
                                "aspect-ratio": "1.5",
                                src: _vm.getVideoThumbnail(video.id),
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center",
                                  attrs: { "x-large": "" },
                                },
                                [_vm._v(" ondemand_video ")]
                              ),
                            ],
                            1
                          ),
                      _c(
                        "v-card-text",
                        { staticClass: "text--primary" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flex justify-begin py-0" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getProjectCreateTime(index)) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "unset" },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h6 white--text primary" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("accountPage.videoStudio.deleteProject")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("div", { staticClass: "text-h6 my-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "accountPage.videoStudio.deleteDialog.deleteText"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteVideoStudioProject()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("globalActions.delete")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.cancelDelete()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("globalActions.cancel")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: "secondary" },
              model: {
                value: _vm.deleteSuccessSnackbar,
                callback: function ($$v) {
                  _vm.deleteSuccessSnackbar = $$v
                },
                expression: "deleteSuccessSnackbar",
              },
            },
            [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("accountPage.videoStudio.deleteDialog.success")
                    ) +
                    " "
                ),
              ]),
            ]
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: "error" },
              model: {
                value: _vm.deleteErrorSnackbar,
                callback: function ($$v) {
                  _vm.deleteErrorSnackbar = $$v
                },
                expression: "deleteErrorSnackbar",
              },
            },
            [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("accountPage.videoStudio.deleteDialog.error")
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }