var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 mx-3" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticClass: "justify-center text-h5" }, [
              _vm._v(" " + _vm._s(_vm.$t("accountPage.captures.title")) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        _vm._l(_vm.captures, function (capture, index) {
          return _c(
            "v-col",
            { key: index, attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                {
                  attrs: { ripple: false },
                  on: {
                    click: function ($event) {
                      return _vm.gotoCapture(capture.id)
                    },
                  },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-click": "",
                        "offset-y": "",
                        "offset-x": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "my-2",
                                      class: _vm.$style.btn,
                                      attrs: {
                                        color: "white",
                                        tile: "",
                                        icon: "",
                                        small: "",
                                        absolute: "",
                                        right: "",
                                        ripple: false,
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { large: "" } }, [
                                      _vm._v(" more_vert "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          capture.compression_type !== 2
                            ? _c(
                                "v-list-group",
                                {
                                  attrs: { "no-action": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "accountPage.captures.presets"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                _vm._l(_vm.presetProfiles, function (profile) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: profile.id,
                                      staticClass: "pl-6",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addPresets(
                                            capture,
                                            profile
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(profile.name),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          capture.compression_type !== 2
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVideo(capture)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("accountPage.captures.video")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdminUser()
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCapture(capture)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "accountPage.captures.deleteTitle"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  capture.thumbnail_url !== undefined ||
                  capture.thumbnail_url !== ""
                    ? _c(
                        "v-img",
                        {
                          staticClass: "white--text align-end",
                          class: _vm.$style.roundedImg,
                          attrs: {
                            src: _vm.getCaptureImage(index),
                            "aspect-ratio": "1.5",
                          },
                        },
                        [
                          capture.uploaded_files < capture.total_files
                            ? _c(
                                "v-card-title",
                                { staticClass: "secondary--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "accountPage.captures.uploading",
                                          [
                                            capture.uploaded_files,
                                            capture.total_files,
                                          ]
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("v-icon", [_vm._v(" account_box ")]),
                  _c(
                    "v-card-text",
                    { staticClass: "text--primary" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-begin py-0" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getCaptureCreateTime(index)) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-end py-0",
                              attrs: { cols: "1" },
                            },
                            [
                              capture.audio_file
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v(" audiotrack ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("AccountCaptureDelete", {
        attrs: { dialog: _vm.showDelete, capture: _vm.delCapture },
        on: {
          close: function ($event) {
            return _vm.closeDelete(false)
          },
          ok: function ($event) {
            return _vm.closeDelete(true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }