var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-12" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "justify-center primary white--text",
                      attrs: { dense: "" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("globalSettingsPage.title")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            slot: "extension",
                            color: "secondary",
                            grow: "",
                            "show-arrows": "",
                          },
                          slot: "extension",
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.components, function (component) {
                          return _c(
                            "v-tab",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !component.hide,
                                  expression: "!component.hide",
                                },
                              ],
                              key: component.id,
                            },
                            [_vm._v(" " + _vm._s(component.name) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.components, function (component, index) {
                          return _c(
                            "v-tab-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !component.hide,
                                  expression: "!component.hide",
                                },
                              ],
                              key: component.id,
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { id: component.id, text: "" } },
                                [
                                  _vm.tab === index
                                    ? _c(component.component, {
                                        tag: "component",
                                        attrs: { id: component.id },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }