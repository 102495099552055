var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { id: "video-container" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 row-height" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 row-height" },
            [
              _c(
                "VideoCanvasControls",
                {
                  ref: "fullscreenFrame",
                  attrs: {
                    "current-time": _vm.currentTime,
                    duration: _vm.duration,
                  },
                  on: {
                    seeked: _vm.seekVideos,
                    played: _vm.playVideos,
                    paused: _vm.pauseVideos,
                  },
                },
                [
                  _c("canvas", {
                    ref: "fullscreenCanvas",
                    staticClass: "player",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.rows, function (row, ri) {
        return _c(
          "v-row",
          { key: ri, staticClass: "hiddenPlayer", attrs: { align: "center" } },
          _vm._l(row, function (video, ci) {
            return _c("v-col", { key: ci }, [
              video !== null
                ? _c("video", {
                    ref: _vm.getIndex(ri, ci),
                    refInFor: true,
                    staticClass: "hiddenPlayer",
                    attrs: {
                      loop: "",
                      preload: "auto",
                      disablePictureInPicture: "",
                      crossorigin: "use-credentials",
                    },
                  })
                : _vm._e(),
            ])
          }),
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }