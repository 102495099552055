var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "tabBackground" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1 settingsTable",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.diagnostics,
                  loading: _vm.loading,
                  options: _vm.options,
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.id))]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.filterTypeId(item.type_id))),
                          ]),
                          _c("td", [_vm._v(_vm._s(item.message))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.getCreateTime(item.message_time))
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.diagnostics.length
                ? _c(
                    "v-btn",
                    {
                      staticClass: "secondary white--text v-btn",
                      on: { click: _vm.exportDiagnostic },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("systemDiagnostics.export")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }