var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "fullscreenDiv",
      staticClass: "video-wrapper",
      class: [
        { hideControl: !_vm.showFullscreen },
        { drag: _vm.dragging },
        { grab: !_vm.dragging },
      ],
      on: {
        mousemove: function ($event) {
          return _vm.mouseMove($event)
        },
        mousedown: function ($event) {
          return _vm.dragStart($event)
        },
        mouseup: _vm.dragEnd,
        dblclick: _vm.doubleClick,
      },
    },
    [
      _c("v-img", {
        attrs: {
          src: _vm.src,
          "max-width": _vm.maxWidth,
          "max-height": _vm.maxHeight,
          "min-width": _vm.maxWidth,
          "min-height": _vm.maxHeight,
          position: _vm.imagePosition,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }