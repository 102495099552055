var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.root },
    [
      _vm.isVideo
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex flex-row" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-h6 mx-3",
                      class: _vm.$style.lowerHeader,
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("capturePage.select.titleVideoStudio")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary d-flex flex-row",
                      attrs: { disabled: _vm.videoSelected },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("add-video")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("capturePage.select.actions.addVideo")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-flex flex-row mx-2",
                      attrs: { disabled: !_vm.videoSelected },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("remove-video")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("capturePage.select.actions.clearVideo")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-flex flex-row mx-2",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("download-video")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("capturePage.select.actions.downloadVideo")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex flex-row" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-h6 mx-3",
                      class: _vm.$style.lowerHeader,
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("capturePage.select.titleImageStudio")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        content: _vm.countSelected,
                        value: _vm.countSelected,
                        color: "primary",
                        overlap: "",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text d-flex flex-row",
                          attrs: {
                            color: "secondary",
                            disabled: _vm.countSelected === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("capturePage.select.actions.addSelected")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2 d-flex flex-row",
                      attrs: { disabled: _vm.countSelected === 0 },
                      on: { click: _vm.clear },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("capturePage.select.actions.clearSelected")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end mr-3" },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        top: "",
                        left: "",
                        "close-on-click": "",
                        "offset-y": "",
                        "offset-x": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c("v-btn", _vm._g({}, on), [_vm._v(" Select ")]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { color: "grey lighten-3" } },
                        [
                          _c(
                            "v-list-group",
                            {
                              attrs: { "no-action": "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "capturePage.select.options.presets"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            _vm._l(_vm.presetProfiles, function (profile) {
                              return _c(
                                "v-list-item",
                                {
                                  key: profile.id,
                                  staticClass: "pl-6",
                                  on: {
                                    click: function ($event) {
                                      _vm.presets = profile.preset_frames
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(profile.name),
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.selectAll = true
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("capturePage.select.options.all")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialog = true
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("capturePage.select.options.range")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("CaptureDetailsSelectDialog", {
                on: {
                  cancel: _vm.cancelSelectFrames,
                  close: _vm.closeSelectFrames,
                },
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }