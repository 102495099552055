var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.capture && _vm.capture.id
    ? _c(
        "v-container",
        { staticClass: "pt-0 px-12" },
        [
          _c(
            "v-row",
            [
              _c("v-breadcrumbs", {
                staticClass: "text-h2!important",
                attrs: { large: "", items: _vm.breadcrumbs },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-breadcrumbs-item",
                            { attrs: { to: item.to, disabled: item.disabled } },
                            [
                              item.disabled
                                ? _c("h1", { staticClass: "subHeader" }, [
                                    _vm._v(" " + _vm._s(item.text) + " "),
                                  ])
                                : _c("h1", { staticClass: "breadcrumbLink" }, [
                                    _vm._v(" " + _vm._s(item.text) + " "),
                                  ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4188674038
                ),
              }),
            ],
            1
          ),
          _c("CaptureDetails", {
            attrs: { capture: _vm.capture },
            on: {
              refresh: function ($event) {
                return _vm.loadInfo()
              },
              "tags-updated": function ($event) {
                return _vm.updateTags($event)
              },
            },
          }),
          _vm.captureNotFound
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", { staticClass: "mt-5" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("capturePage.notFound")) + " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }