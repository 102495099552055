var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 px-12" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticClass: "subHeader" }, [
              _vm._v(" " + _vm._s(_vm.$t("videoStudioReviewPage.title")) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "9" } }),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  on: {
                    click: function ($event) {
                      _vm.infoDialog = true
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("videoStudioReviewPage.info")) + " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadVideo()
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("videoStudioReviewPage.download")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-row", { staticClass: "justify-center" }, [
                _c("video", {
                  attrs: {
                    width: "75%",
                    height: "auto%",
                    controls: "",
                    disablePictureInPicture: "",
                    controlsList: "nodownload",
                    src: _vm.videoUrl,
                    crossorigin: "use-credentials",
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.infoDialog,
            callback: function ($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("videoStudioReviewPage.infoTitle")) +
                      " "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.currentTab,
                        callback: function ($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab",
                      },
                    },
                    [
                      _vm._l(_vm.tabs, function (tab, index) {
                        return _c(
                          "v-tab",
                          { key: index, attrs: { href: "#tab-" + index } },
                          [_vm._v(" " + _vm._s(tab) + " ")]
                        )
                      }),
                      _vm._l(_vm.tabs, function (tab, index) {
                        return _c(
                          "v-tab-item",
                          { key: index, attrs: { value: "tab-" + index } },
                          [
                            _c(
                              "v-card",
                              { attrs: { flat: "", tile: "" } },
                              [
                                index === 0
                                  ? _c(
                                      "v-card-text",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            value: _vm.exportType,
                                            label: _vm.$t(
                                              "videoStudioReviewPage.exportType"
                                            ),
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-list-item-group",
                                          { attrs: { color: "primary" } },
                                          [
                                            _c(
                                              "v-list",
                                              _vm._l(
                                                _vm.exportInfo.playlists[
                                                  index - 1
                                                ],
                                                function (playlist, idx) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: idx,
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.gotoCapture(
                                                            index - 1,
                                                            idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.captureInfo(
                                                                      index - 1,
                                                                      idx
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-list-item-title"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }