// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CaptureDetails3DSelect_root_UZipg {\n  background-color: #E3E3E3;\n}\n.CaptureDetails3DSelect_lowerHeader_lvpSl {\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://./src/components/Capture/CaptureDetails3DSelect.vue","webpack://./src/css/variables.scss"],"names":[],"mappings":"AAyFA;EACE,yBCjFO;ADPT;AA2FA;EACE,mBAAA;AAxFF","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n@import '../../css/variables';\n\n.root {\n  background-color: $grey-1;\n}\n\n.lowerHeader {\n  white-space:nowrap;\n}\n\n","// Colors\n$orange-text: #E65100;\n$gray-text: #757575;\n$grey-lighten-3: #EEEEEE;\n$grey-lighten-2: #E0E0E0;\n$grey-lighten-1: #F5F5F5;\n$grey-darken-1: #757575;\n$grey-darken-2: #333333;\n$grey-darken-3: #C4C4C4;\n$grey-1: #E3E3E3;\n$grey-2: #DCDCDC;\n$grey-3: #F2F2F2;\n$grey-4: #CCCCCC;\n$grey-5: #E5E5E5;\n$grey-6: #FAFAFA;\n$black-text: #1B1B1B;\n$blue-title: #17607D;\n$blue-title-2: #1976D2;\n\n//Width\n$container-width: 1200px;\n$container-padding: 10px 0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CaptureDetails3DSelect_root_UZipg",
	"lowerHeader": "CaptureDetails3DSelect_lowerHeader_lvpSl"
};
export default ___CSS_LOADER_EXPORT___;
