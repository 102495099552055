var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-row", { staticClass: "mt-2" }, [
        _c("h1", { staticClass: "subHeader" }, [
          _vm._v(" " + _vm._s(_vm.$t("tagsPage.title")) + " "),
        ]),
      ]),
      _c("v-data-table", {
        staticClass: "account-table mt-4",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.tags,
          loading: _vm.loading,
          "must-sort": true,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "tr",
                  _vm._l(_vm.tableHeaders, function (header) {
                    return _c(
                      "td",
                      {
                        key: header.value + item.id,
                        class: "text-xs-" + header.align,
                        on: {
                          click: function ($event) {
                            return _vm.gotoCapturePage(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item[header.value]) + " ")]
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }