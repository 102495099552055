var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 px-12" },
    [
      _c(
        "v-row",
        [
          _c("v-breadcrumbs", {
            staticClass: "text-h2!important",
            attrs: { large: "", items: _vm.breadcrumbs },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-breadcrumbs-item",
                      { attrs: { to: item.to, disabled: item.disabled } },
                      [
                        item.disabled
                          ? _c("h1", { staticClass: "subHeader" }, [
                              _vm._v(" " + _vm._s(item.text) + " "),
                            ])
                          : _c("h1", { staticClass: "breadcrumbLink" }, [
                              _vm._v(" " + _vm._s(item.text) + " "),
                            ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-row",
        [_c("v-col", [_c("ImageEditor", { ref: "imageEditor" })], 1)],
        1
      ),
      _c(
        "v-navigation-drawer",
        { attrs: { app: "", clipped: "", right: "", width: "275" } },
        [
          _c("h2", { staticClass: "pl-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("imageStudio.sideBar.accounts")) + " "),
          ]),
          _c(
            "v-list",
            _vm._l(_vm.accounts, function (account) {
              return _c(
                "v-list-group",
                {
                  key: account.accountId,
                  attrs: { value: "true" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c("v-list-item-title", [
                              _vm._v(" " + _vm._s(account.accountName) + " "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                _vm._l(account.captures, function (capture) {
                  return _c(
                    "v-list-group",
                    {
                      key: capture.captureId,
                      attrs: {
                        "no-action": "",
                        "sub-group": "",
                        value: "true",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "Capture Id: " + capture.captureId
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          top: "",
                                          "close-on-click": "",
                                          "offset-y": "",
                                          "offset-x": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      { attrs: { icon: "" } },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey lighten-1",
                                                          },
                                                        },
                                                        [_vm._v(" more_vert ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            attrs: { color: "grey lighten-3" },
                                          },
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeCaptureFromStudio(
                                                          capture.captureId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "imageStudio.sideBar.removeFromStudio"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    _vm._l(capture.frames, function (frame) {
                      return _c(
                        "v-list-item",
                        { key: frame.frame, staticClass: "pl-8" },
                        [
                          _c(
                            "v-list-item-avatar",
                            { attrs: { "min-height": "50px" } },
                            [
                              _c("v-img", {
                                staticClass: "white--text align-end",
                                attrs: { src: _vm.getApiUrl(frame.image) },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s("Frame - " + (frame.frame + 1)) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    top: "",
                                    "close-on-click": "",
                                    "offset-y": "",
                                    "offset-x": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                { attrs: { icon: "" } },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "grey lighten-1",
                                                    },
                                                  },
                                                  [_vm._v(" more_vert ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { color: "grey lighten-3" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addToPage(
                                                    capture.captureId,
                                                    frame
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "imageStudio.sideBar.addToPage"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFromStudio(
                                                    capture.captureId,
                                                    frame
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "imageStudio.sideBar.removeFromStudio"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                }),
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }