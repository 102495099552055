var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "justify-center primary white--text",
          class: _vm.$style.title,
          attrs: { dense: "" },
        },
        [
          _c("v-toolbar-title", [
            _vm._v(_vm._s(_vm.$t("accountPage.details.title"))),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "white", icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("edit")
                },
              },
            },
            [_c("v-icon", [_vm._v("edit")])],
            1
          ),
          _vm.isAdminUser()
            ? _c(
                "v-btn",
                {
                  attrs: { color: "white", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("delete")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("delete")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-form",
        { ref: "form", class: _vm.$style.form },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                _vm._l(_vm.accountFields, function (field) {
                  return _c(
                    "v-col",
                    {
                      key: field.id,
                      staticClass: "p-1 rows",
                      attrs: { cols: field.width },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: field.description,
                          value: _vm.getValue(field),
                          color: "grey darken-1",
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }