var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "justify-center" },
    [
      _c(
        "v-btn-toggle",
        {
          attrs: { color: "secondary", group: "", mandatory: "" },
          model: {
            value: _vm.view,
            callback: function ($$v) {
              _vm.view = $$v
            },
            expression: "view",
          },
        },
        [
          !_vm.show3dviewer
            ? _c(
                "v-btn",
                {
                  staticClass: "orange--text text--darken-3",
                  attrs: { value: _vm.views.slides, large: "" },
                },
                [_c("v-icon", [_vm._v("view_carousel")])],
                1
              )
            : _vm._e(),
          !_vm.show3dviewer
            ? _c(
                "v-btn",
                {
                  staticClass: "orange--text text--darken-3",
                  attrs: { value: _vm.views.tiles2, large: "" },
                },
                [_c("v-icon", [_vm._v("border_all")])],
                1
              )
            : _vm._e(),
          !_vm.show3dviewer
            ? _c(
                "v-btn",
                {
                  staticClass: "orange--text text--darken-3",
                  attrs: { value: _vm.views.tiles3, large: "" },
                },
                [_c("v-icon", [_vm._v("grid_on")])],
                1
              )
            : _vm._e(),
          !_vm.show3dviewer
            ? _c(
                "v-btn",
                {
                  staticClass: "orange--text text--darken-3",
                  attrs: { value: _vm.views.video, large: "" },
                },
                [_c("v-icon", [_vm._v("slideshow")])],
                1
              )
            : _vm._e(),
          _vm.show3dviewer
            ? _c(
                "v-btn",
                {
                  staticClass: "orange--text text--darken-3",
                  attrs: { value: _vm.views.viewer3d, large: "" },
                },
                [_c("v-icon", [_vm._v("3d_rotation")])],
                1
              )
            : _vm._e(),
          _vm.showaudio
            ? _c(
                "v-btn",
                {
                  staticClass: "orange--text text--darken-3",
                  attrs: { value: _vm.views.audio, large: "" },
                },
                [_c("v-icon", [_vm._v("audiotrack")])],
                1
              )
            : _vm._e(),
          _vm.showavatar
            ? _c(
                "v-btn",
                {
                  staticClass: "orange--text text--darken-3",
                  attrs: { value: _vm.views.avatar, large: "" },
                },
                [_c("v-icon", [_vm._v("account_box")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }