var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      on: {
        "click:outside": function ($event) {
          return _vm.close()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-h6 white--text primary" },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("capturePage.captureInfoTitle")) + " "
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                [
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "v-tab",
                      { key: index, attrs: { href: "#tab-" + index } },
                      [_vm._v(" " + _vm._s(tab) + " ")]
                    )
                  }),
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "v-tab-item",
                      { key: index, attrs: { value: "tab-" + index } },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "", tile: "" } },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c("v-combobox", {
                                  attrs: {
                                    items: _vm.tags,
                                    "item-text": "name",
                                    "item-value": "id",
                                    color: "secondary",
                                    "item-color": "secondary",
                                    label: _vm.$t("capturePage.filterTags"),
                                    "search-input": _vm.tagSearch,
                                    multiple: "",
                                    chips: "",
                                    "deletable-chips": "",
                                  },
                                  on: {
                                    "update:searchInput": function ($event) {
                                      _vm.tagSearch = $event
                                    },
                                    "update:search-input": function ($event) {
                                      _vm.tagSearch = $event
                                    },
                                    input: _vm.autoInput,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "no-data",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "capturePage.createTag"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.selectedTags,
                                    callback: function ($$v) {
                                      _vm.selectedTags = $$v
                                    },
                                    expression: "selectedTags",
                                  },
                                }),
                                _vm._l(
                                  index === 0
                                    ? _vm.captureInfo
                                    : _vm.cameraInfo,
                                  function (setting, idx) {
                                    return _c("v-text-field", {
                                      key: idx,
                                      attrs: {
                                        value: _vm.textValue(
                                          index,
                                          setting.key
                                        ),
                                        label: setting.title,
                                        readonly: "",
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("v-card-text"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }