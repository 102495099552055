var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        color: "$vuetify.themes[theme].headerBackground",
        app: "",
        "clipped-left": "",
        "clipped-right": "",
      },
    },
    [
      _c("v-app-bar-nav-icon", {
        attrs: { color: "secondary" },
        on: { click: _vm.navBarToggle },
      }),
      _c("v-img", {
        staticClass: "mx-4",
        attrs: {
          src: require("@/assets/logo.png"),
          "max-height": "50",
          "max-width": "274",
          contain: "",
        },
      }),
      _c("v-combobox", {
        staticClass: "mt-8 mx-8 px-8",
        attrs: {
          items: _vm.accountNames,
          "search-input": _vm.searchText,
          "solo-inverted": "",
          label: _vm.$t("header.search"),
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.searchText = $event
          },
          "update:search-input": function ($event) {
            _vm.searchText = $event
          },
          keydown: _vm.searchInput,
          change: _vm.searchAccount,
        },
        model: {
          value: _vm.selectedAccount,
          callback: function ($$v) {
            _vm.selectedAccount = $$v
          },
          expression: "selectedAccount",
        },
      }),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: { auto: "", "offset-y": "", "open-on-click": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g({}, on),
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "mr-1",
                          attrs: { size: "32px", item: "" },
                        },
                        [
                          _vm.user && _vm.user.image
                            ? _c("img", {
                                staticClass: "userHeaderAvatar",
                                attrs: { src: _vm.user.image },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/user-logo.png"),
                                  alt: "User",
                                },
                              }),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.user.username))]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { on: { click: _vm.goToUser } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("header.account"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.logout } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("header.logOut"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "unset" },
          model: {
            value: _vm.studioConfirmation.showDialog,
            callback: function ($$v) {
              _vm.$set(_vm.studioConfirmation, "showDialog", $$v)
            },
            expression: "studioConfirmation.showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "test-h6 white--text primary" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("studioConfirmationDialog.title")) + " "
                  ),
                ]
              ),
              _c("v-card-text", [
                _c("div", { staticClass: "mt-4" }, [
                  _vm.studioConfirmation.isImageStudio
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "studioConfirmationDialog.imageStudioDescription"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.studioConfirmation.isVideoStudio
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "studioConfirmationDialog.videoStudioDescription"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: { click: _vm.submitLogout },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("header.logOut")) + " ")]
                  ),
                  _c("v-btn", { on: { click: _vm.closeConfirmationDialog } }, [
                    _vm._v(" " + _vm._s(_vm.$t("globalActions.cancel")) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }