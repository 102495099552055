var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-12" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticClass: "subHeader" }, [
              _vm._v(" " + _vm._s(_vm.$t("accountPage.title")) + " "),
            ]),
          ]),
        ],
        1
      ),
      _vm.account.id
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("AccountDetails", {
                        attrs: {
                          "v-if": _vm.account.id,
                          "account-values": _vm.account.fields,
                        },
                        on: {
                          edit: _vm.openEditDialog,
                          delete: _vm.openDeleteDialog,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("AccountCaptures", {
                                attrs: { account: _vm.account },
                                on: { update: _vm.loadAccountData },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("AccountImageStudio", {
                                attrs: { account: _vm.account },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("AccountVideoStudio", {
                                attrs: { account: _vm.account },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("AccountDocuments", {
                                attrs: { account: _vm.account },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("AccountData", {
                attrs: { dialog: _vm.showEditAccount, account: _vm.account },
                on: {
                  close: function ($event) {
                    _vm.showEditAccount = false
                  },
                  saved: _vm.savedAccount,
                  error: _vm.savedError,
                },
              }),
              _c("AccountDelete", {
                attrs: { dialog: _vm.showDeleteAccount, account: _vm.account },
                on: {
                  close: function ($event) {
                    _vm.showDeleteAccount = false
                  },
                  ok: _vm.goToAccountsPage,
                },
              }),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "400" },
                  model: {
                    value: _vm.showDeleteError,
                    callback: function ($$v) {
                      _vm.showDeleteError = $$v
                    },
                    expression: "showDeleteError",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h6 white--text primary" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "accountPage.captureAssociatedErrorTitle"
                                )
                              ) +
                              " "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showDeleteError = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v(" close ")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-text", { staticClass: "mt-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("accountPage.captureAssociatedError")
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.accountNotFound
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", { staticClass: "mt-5" }, [
                      _vm._v(
                        " 404 " + _vm._s(_vm.$t("accountPage.notFound")) + " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.sucessSnackbar,
            callback: function ($$v) {
              _vm.sucessSnackbar = $$v
            },
            expression: "sucessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("addAccount.success")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("addAccount.error")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }