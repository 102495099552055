var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "tabBackground" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("presetFrameProfiles.add")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: _vm.selectedProfile.length === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.openUpdateDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("presetFrameProfiles.edit")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: _vm.selectedProfile.length === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.openDeleteDialog()
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("presetFrameProfiles.delete")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.profiles,
                  loading: _vm.loading,
                  "item-key": "id",
                  color: "grey darken-1",
                  "show-select": "",
                  "single-select": "",
                  "sort-by": "name",
                },
                model: {
                  value: _vm.selectedProfile,
                  callback: function ($$v) {
                    _vm.selectedProfile = $$v
                  },
                  expression: "selectedProfile",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "unset" },
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "tabDialog" },
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("presetFrameProfiles.deleteDialog.title")) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("div", { staticClass: "text-h6 my-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "presetFrameProfiles.deleteDialog.message"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteProfile()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("presetFrameProfiles.deleteDialog.submit")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancelDelete()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("presetFrameProfiles.deleteDialog.cancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "unset" },
          model: {
            value: _vm.addDialog,
            callback: function ($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var invalid = ref.invalid
                  var handleSubmit = ref.handleSubmit
                  return [
                    _c(
                      "v-card",
                      { staticClass: "tabDialog" },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "text-h6 white--text primary" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isUpdateMode
                                    ? _vm.$t(
                                        "presetFrameProfiles.updateDialog.title"
                                      )
                                    : _vm.$t(
                                        "presetFrameProfiles.addDialog.title"
                                      )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "ValidationProvider",
                                      {
                                        attrs: {
                                          name: "name",
                                          rules: "required|max:255",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t(
                                              "presetFrameProfiles.addDialog.name"
                                            ),
                                          },
                                          model: {
                                            value: _vm.newProfile.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newProfile,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "newProfile.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "ValidationProvider",
                                      {
                                        attrs: {
                                          name: "preset_frames",
                                          rules: "required",
                                        },
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.availablePresetFrames,
                                            label: _vm.$t(
                                              "presetFrameProfiles.addDialog.presetFrames"
                                            ),
                                            chips: "",
                                            multiple: "",
                                            required: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function (ref) {
                                                  var attrs = ref.attrs
                                                  var item = ref.item
                                                  var select = ref.select
                                                  var selected = ref.selected
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "secondary",
                                                          attrs: {
                                                            "input-value":
                                                              selected,
                                                            color: "white",
                                                            close: "",
                                                          },
                                                          on: {
                                                            click: select,
                                                            "click:close":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.removePreset(
                                                                  item
                                                                )
                                                              },
                                                          },
                                                        },
                                                        "v-chip",
                                                        attrs,
                                                        false
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.newProfile.preset_frames,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newProfile,
                                                "preset_frames",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "newProfile.preset_frames",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "secondary",
                                  disabled: invalid,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.isUpdateMode
                                      ? handleSubmit(_vm.updateProfile)
                                      : handleSubmit(_vm.addProfile)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isUpdateMode
                                        ? _vm.$t(
                                            "presetFrameProfiles.updateDialog.submit"
                                          )
                                        : _vm.$t(
                                            "presetFrameProfiles.addDialog.submit"
                                          )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelAdd()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "presetFrameProfiles.addDialog.cancel"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.deleteSuccessSnackbar,
            callback: function ($$v) {
              _vm.deleteSuccessSnackbar = $$v
            },
            expression: "deleteSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("presetFrameProfiles.deleteDialog.success")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.deleteErrorSnackbar,
            callback: function ($$v) {
              _vm.deleteErrorSnackbar = $$v
            },
            expression: "deleteErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("presetFrameProfiles.deleteDialog.error")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.addSuccessSnackbar,
            callback: function ($$v) {
              _vm.addSuccessSnackbar = $$v
            },
            expression: "addSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("presetFrameProfiles.addDialog.success")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.addErrorSnackbar,
            callback: function ($$v) {
              _vm.addErrorSnackbar = $$v
            },
            expression: "addErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("presetFrameProfiles.addDialog.error")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.updateSuccessSnackbar,
            callback: function ($$v) {
              _vm.updateSuccessSnackbar = $$v
            },
            expression: "updateSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("presetFrameProfiles.updateDialog.success")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.updateErrorSnackbar,
            callback: function ($$v) {
              _vm.updateErrorSnackbar = $$v
            },
            expression: "updateErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("presetFrameProfiles.updateDialog.error")) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }