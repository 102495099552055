var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "" },
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            [
              _c(
                "v-list-item",
                { attrs: { link: "", to: { name: _vm.accountsPage } } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("account_circle")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.$t("sidePanel.accounts"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { link: "", to: { name: _vm.capturesPage } } },
                [
                  _c("v-list-item-icon", [_c("v-icon", [_vm._v("photo")])], 1),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.$t("sidePanel.captures"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { link: "", to: { name: _vm.tagsPage } } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("tag_faces")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.$t("sidePanel.tags"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { link: "", to: { name: _vm.imageStudioPage } } },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            content: _vm.imageStudioCount(),
                            value: _vm.imageStudioCount(),
                            color: "secondary",
                            overlap: "",
                          },
                        },
                        [_c("v-icon", [_vm._v("photo_library")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("sidePanel.imageStudio"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { link: "", to: { name: _vm.videoStudioPage } } },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            content: _vm.videoStudioCount(),
                            value: _vm.videoStudioCount(),
                            color: "secondary",
                            overlap: "",
                          },
                        },
                        [_c("v-icon", [_vm._v("movie_filter")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("sidePanel.videoStudio"))),
                  ]),
                ],
                1
              ),
              _vm.isAdminUser()
                ? _c(
                    "v-list-item",
                    { attrs: { link: "", to: { name: _vm.settingsPage } } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("settings")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("sidePanel.settings"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                {
                  attrs: { link: "", href: _vm.getHelpUrl(), target: "_blank" },
                },
                [
                  _c("v-list-item-icon", [_c("v-icon", [_vm._v("help")])], 1),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("sidePanel.help"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }