var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "justify-center" },
    _vm._l(_vm.pageInfo.images, function (page, index) {
      return _c(
        "v-col",
        { key: index, staticClass: "py-0", attrs: { cols: "6" } },
        [
          _c(
            "v-row",
            [
              _c("v-spacer"),
              _c("Editor", {
                ref: "editor" + index,
                refInFor: true,
                attrs: {
                  "canvas-width": _vm.canvasWidth + index,
                  "canvas-height": _vm.canvasHeight + index,
                  width: _vm.canvasWidth + index,
                  height: _vm.canvasHeight + index,
                  "editor-number": index,
                },
                on: {
                  "image-loaded": function ($event) {
                    return _vm.imageLoaded()
                  },
                },
              }),
              _c("v-spacer"),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "1" } }),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "6" } },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          "Capture Id: " +
                            page.captureId +
                            " Frame:" +
                            (page.frame + 1)
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c("v-spacer"),
              _c("v-col", { attrs: { cols: "3" } }),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-click": "",
                        "offset-y": "",
                        "offset-x": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: "secondary",
                                        icon: "",
                                        tile: "",
                                        ripple: false,
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v(" more_horiz ")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.undo(index)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("imageStudio.undo")) + " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.clear(index)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("imageStudio.clear")) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.removeFromPage(index)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("imageStudio.removePage")) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "1" } }),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }