var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-2" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end", attrs: { cols: "10" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddDialog(true)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("locationsTab.addBtn")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: _vm.selectedLocation.length === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.openAddDialog(false)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("locationsTab.editBtn")) + " ")]
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.locations,
                  loading: _vm.loading,
                  "item-key": "id",
                  "show-select": "",
                  "single-select": "",
                },
                model: {
                  value: _vm.selectedLocation,
                  callback: function ($$v) {
                    _vm.selectedLocation = $$v
                  },
                  expression: "selectedLocation",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.addDialog,
            callback: function ($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog",
          },
        },
        [
          _c(
            "v-form",
            [
              _c("ValidationObserver", {
                ref: "obs",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var invalid = ref.invalid
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "text-h6 white--text primary" },
                              [
                                _c("span", { staticClass: "text-h5" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.addMode
                                          ? _vm.$t(
                                              "locationsTab.addLocation.title"
                                            )
                                          : _vm.$t(
                                              "locationsTab.editLocation.title"
                                            )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var valid = ref.valid
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            label: _vm.$t(
                                                              "locationsTab.addLocation.name"
                                                            ),
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newLocation
                                                                .name,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newLocation,
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newLocation.name",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "locationsTab.addLocation.street"
                                                ),
                                              },
                                              model: {
                                                value: _vm.newLocation.street,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLocation,
                                                    "street",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newLocation.street",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "locationsTab.addLocation.city"
                                                ),
                                              },
                                              model: {
                                                value: _vm.newLocation.city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLocation,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression: "newLocation.city",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "locationsTab.addLocation.state"
                                                ),
                                              },
                                              model: {
                                                value: _vm.newLocation.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLocation,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression: "newLocation.state",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "locationsTab.addLocation.zip"
                                                ),
                                              },
                                              model: {
                                                value: _vm.newLocation.zipcode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLocation,
                                                    "zipcode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newLocation.zipcode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "locationsTab.addLocation.contact"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.newLocation
                                                    .primary_contact,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLocation,
                                                    "primary_contact",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newLocation.primary_contact",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "locationsTab.addLocation.phone"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.newLocation.contact_phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newLocation,
                                                    "contact_phone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newLocation.contact_phone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-4" },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("ValidationProvider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var valid = ref.valid
                                                      return [
                                                        _c("v-autocomplete", {
                                                          attrs: {
                                                            items:
                                                              _vm.timezones,
                                                            "item-text":
                                                              "friendly_name",
                                                            "menu-props":
                                                              "auto",
                                                            label: _vm.$t(
                                                              "locationsTab.addLocation.timezone"
                                                            ),
                                                            disabled:
                                                              _vm.newLocation
                                                                .id > 0,
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            "single-line": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newLocation
                                                                .timezone,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newLocation,
                                                                "timezone",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newLocation.timezone",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "ma-4" },
                                  [
                                    _vm.newLocation.id <= 0
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-1",
                                            attrs: {
                                              color: "secondary",
                                              disabled: invalid,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.addLocation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "locationsTab.addLocation.add"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-1",
                                            attrs: {
                                              color: "secondary",
                                              disabled: invalid,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.updateLocation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "locationsTab.addLocation.save"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-1",
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancel()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "locationsTab.addLocation.cancel"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackBarTimeout, color: "secondary" },
          model: {
            value: _vm.saveFinished,
            callback: function ($$v) {
              _vm.saveFinished = $$v
            },
            expression: "saveFinished",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("locationsTab.saveFinished")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackBarTimeout, color: "error" },
          model: {
            value: _vm.saveError,
            callback: function ($$v) {
              _vm.saveError = $$v
            },
            expression: "saveError",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("locationsTab.saveError")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }