var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "text-h5" }, [
              _vm._v(_vm._s(_vm.$t("capturePage.select.dialog.title"))),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            class: _vm.$style.fields,
                            attrs: {
                              label: _vm.$t("capturePage.select.dialog.from"),
                              color: "grey darken-1",
                              type: "number",
                              min: "1",
                            },
                            model: {
                              value: _vm.selectFrom,
                              callback: function ($$v) {
                                _vm.selectFrom = $$v
                              },
                              expression: "selectFrom",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            class: _vm.$style.fields,
                            attrs: {
                              label: _vm.$t("capturePage.select.dialog.to"),
                              color: "grey darken-1",
                              type: "number",
                              min: "1",
                            },
                            model: {
                              value: _vm.selectTo,
                              callback: function ($$v) {
                                _vm.selectTo = $$v
                              },
                              expression: "selectTo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            class: _vm.$style.fields,
                            attrs: {
                              label: _vm.$t("capturePage.select.dialog.every"),
                              color: "grey darken-1",
                              type: "number",
                              min: "1",
                            },
                            model: {
                              value: _vm.selectEvery,
                              callback: function ($$v) {
                                _vm.selectEvery = $$v
                              },
                              expression: "selectEvery",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.closeDialog },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("capturePage.select.dialog.accept")) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.cancelDialog },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("capturePage.select.dialog.cancel")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }