var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-wrapper clickable",
      on: { mouseover: _vm.showControls, mouseleave: _vm.hideControls },
    },
    [
      _vm._t("default"),
      _c(
        "v-container",
        {
          staticClass: "controls-wrapper pa-0",
          class: { show: _vm.show },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "ml-5 mr-5" },
            [
              _c(
                "v-col",
                {
                  staticClass: "progress-bar clickable pa-0",
                  attrs: { "align-self": "center" },
                  on: {
                    click: function ($event) {
                      return _vm.seek($event)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "progress-bar-total" }),
                  _c("div", {
                    staticClass: "progress-bar-buffered",
                    style: _vm.progressBarBufferedStyle,
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "progress-bar-played",
                      style: _vm.progressBarPlayedStyle,
                    },
                    [
                      _c("div", {
                        staticClass: "progress-bar-scrubber-new",
                        on: {
                          mousedown: function ($event) {
                            $event.stopPropagation()
                            return _vm.onSlide(_vm.seek)
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ml-5 mr-5" },
            [
              _c(
                "v-col",
                { staticClass: "pl-0 pr-0" },
                [
                  _vm.showAdvancedControls
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "white" },
                          on: { click: _vm.previous },
                        },
                        [_c("v-icon", [_vm._v(" skip_previous ")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "white" },
                      on: { click: _vm.togglePlay },
                    },
                    [
                      !_vm.paused
                        ? _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" pause "),
                          ])
                        : _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" play_arrow "),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c("v-col", { staticClass: "d-flex justify-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "time control-element-wrapper",
                    attrs: { cols: "1" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("timestamp")(_vm.currentTime)) +
                        " / " +
                        _vm._s(_vm._f("timestamp")(_vm.duration)) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("v-spacer"),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "white" },
                      on: { click: _vm.toggleFullscreen },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(" fullscreen "),
                      ]),
                    ],
                    1
                  ),
                  _vm.showAdvancedControls
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "white" },
                          on: { click: _vm.next },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" skip_next "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }