var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      on: {
        "click:outside": function ($event) {
          return _vm.close()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-h6 white--text primary" },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("capturePage.captureReassignTitle")) + " "
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-text-field", {
                    staticClass: "pt-6",
                    attrs: {
                      value: _vm.capture.account_name,
                      label: _vm.$t("capturePage.currentAccount"),
                      readonly: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.accountNames,
                      "search-input": _vm.searchText,
                      loading: _vm.isLoading,
                      "solo-inverted": "",
                      label: _vm.$t("capturePage.newAccount"),
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.searchText = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchText = $event
                      },
                    },
                    model: {
                      value: _vm.selectedAccount,
                      callback: function ($$v) {
                        _vm.selectedAccount = $$v
                      },
                      expression: "selectedAccount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.selectedAccount,
                    color: "secondary white--text v-btn",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("globalActions.save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "gray darken-3" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("globalActions.cancel")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }