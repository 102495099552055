var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "no-gutter pt-0 mt-0" },
    [
      _c(
        "v-row",
        { staticClass: "no-gutter" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  attrs: {
                    outlined: "",
                    loading:
                      _vm.is3DViewer && _vm.modelLoading ? "secondary" : false,
                  },
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "justify-center primary white--text",
                      class: _vm.$style.title,
                      attrs: { dense: "", elevation: "0" },
                    },
                    [
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.getTitle()))]),
                      _c("v-spacer"),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-2",
                                        class: _vm.$style.navigation,
                                        attrs: { color: "white", icon: "" },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("more_horiz")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.infoDialog = true
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("capturePage.information"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportCapture()
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("capturePage.export"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm.capture.compression_type !== 2 &&
                              !_vm.capture.has_avatar
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.createAvatar()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("capturePage.createAvatar")
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAdminUser()
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showReassign = true
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "capturePage.reassignCapture"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAdminUser()
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showDelete = true
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(_vm.$t("capturePage.delete"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("CaptureDetailsView", {
                                attrs: {
                                  show3dviewer:
                                    _vm.capture.compression_type === 2,
                                  showaudio:
                                    _vm.capture.audio_file !== undefined &&
                                    _vm.capture.audio_file !== "",
                                  showavatar: _vm.capture.has_avatar,
                                },
                                on: { "view-changed": _vm.changeView },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.is3DViewer && !_vm.isAudio && !_vm.isVideo
                        ? _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.images.length
                                    ? _c("CaptureDetailsItems", {
                                        attrs: {
                                          items: _vm.images,
                                          "selected-items": _vm.selectedImages,
                                          view: _vm.view,
                                        },
                                        on: { select: _vm.toggleImage },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.isVideo
                        ? _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-col"),
                              _c("v-col", [
                                _c(
                                  "div",
                                  [
                                    _vm.isVideoInImageStudio
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "white--text ma-2",
                                            attrs: {
                                              color: "secondary",
                                              small: "",
                                              fab: "",
                                              absolute: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { large: "" } },
                                              [_vm._v(" check ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "video",
                                      {
                                        attrs: {
                                          width: 600,
                                          height: "auto%",
                                          controls: "",
                                          disablePictureInPicture: "",
                                          controlsList: "nodownload",
                                          src: _vm.videoUrl,
                                          crossorigin: "use-credentials",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "capturePage.videoNotSupport"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("v-col"),
                            ],
                            1
                          )
                        : _vm.isAudio
                        ? _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col"),
                                      _c("v-col", { attrs: { md: "auto" } }, [
                                        _c(
                                          "span",
                                          { staticClass: "text-h6 mx-3" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "capturePage.audioPlayer"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("v-col"),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col"),
                                      _c("v-col", { attrs: { md: "auto" } }, [
                                        _c(
                                          "audio",
                                          {
                                            attrs: {
                                              width: "100%",
                                              height: "auto%",
                                              justify: "center",
                                              controls: "",
                                              controlsList: "nodownload",
                                              crossorigin: "use-credentials",
                                            },
                                          },
                                          [
                                            _c("source", {
                                              attrs: {
                                                src: _vm.audioUrl,
                                                type: "audio/wav",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "capturePage.videoNotSupport"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("v-col"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.is3DViewer
                        ? _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("ModelPly", {
                                ref: "plyModel",
                                staticClass: "canvas",
                                attrs: {
                                  height: 600,
                                  width: 800,
                                  src: _vm.captureModelUrl,
                                  "texture-url": _vm.captureModelTexture,
                                },
                                on: { "on-load": _vm.onModelLoad },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.is3DViewer
                    ? _c("CaptureDetailsSelect", {
                        attrs: {
                          view: _vm.view,
                          "count-selected": _vm.countSelected,
                          "all-selected":
                            _vm.selectedImages.length === _vm.images.length,
                          "video-selected": _vm.isVideoInImageStudio,
                          "preset-profiles": _vm.presets,
                          margin: "16",
                        },
                        on: {
                          clear: _vm.clearSelectedImages,
                          add: _vm.addToImageStudio,
                          "add-video": _vm.addVideo,
                          "select-by-filters": _vm.selectByFilters,
                          "remove-video": _vm.removeVideo,
                          "download-video": _vm.downloadVideo,
                        },
                      })
                    : _vm.is3DViewer
                    ? _c("CaptureDetails3DSelect", {
                        attrs: { "is-avatar": _vm.isAvatar },
                        on: {
                          export: _vm.export3D,
                          "render-mode-changed": _vm.renderModeChanged,
                        },
                        model: {
                          value: _vm.renderMode,
                          callback: function ($$v) {
                            _vm.renderMode = $$v
                          },
                          expression: "renderMode",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CaptureDetailsInfo", {
        attrs: { "show-dialog": _vm.infoDialog, capture: _vm.capture },
        on: {
          closed: function ($event) {
            _vm.infoDialog = false
          },
          refresh: function ($event) {
            return _vm.$emit("refresh")
          },
        },
      }),
      _c("AccountCaptureDelete", {
        attrs: { dialog: _vm.showDelete, capture: _vm.capture },
        on: {
          close: function ($event) {
            return _vm.closeDelete(false)
          },
          ok: function ($event) {
            return _vm.closeDelete(true)
          },
        },
      }),
      _c("CaptureReassign", {
        attrs: { "show-dialog": _vm.showReassign, capture: _vm.capture },
        on: {
          close: function ($event) {
            return _vm.closeReassign(false)
          },
          ok: function ($event) {
            return _vm.closeReassign(true)
          },
          error: function ($event) {
            return _vm.reassignError()
          },
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.reassignSuccessSnackbar,
            callback: function ($$v) {
              _vm.reassignSuccessSnackbar = $$v
            },
            expression: "reassignSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("capturePage.reassignSuccess")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.createAvatarSnackbar,
            callback: function ($$v) {
              _vm.createAvatarSnackbar = $$v
            },
            expression: "createAvatarSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("capturePage.createAvatarSuccess")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.localExportSnackbar,
            callback: function ($$v) {
              _vm.localExportSnackbar = $$v
            },
            expression: "localExportSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("capturePage.localExportCapture")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.cloudExportSnackbar,
            callback: function ($$v) {
              _vm.cloudExportSnackbar = $$v
            },
            expression: "cloudExportSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("capturePage.cloudExportCapture")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.exportErrorSnackbar,
            callback: function ($$v) {
              _vm.exportErrorSnackbar = $$v
            },
            expression: "exportErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("capturePage.exportInProgress")) + " "),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.addErrorSnackbar,
            callback: function ($$v) {
              _vm.addErrorSnackbar = $$v
            },
            expression: "addErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("capturePage.reassignError")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }