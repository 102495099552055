var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "pt-0 justify-center" },
    [
      _c(
        "v-col",
        { staticClass: "pt-0" },
        [
          _c(
            "v-row",
            { staticClass: "justify-center mb-2" },
            [
              _c("v-col"),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: { color: "secondary", group: "" },
                      model: {
                        value: _vm.mode,
                        callback: function ($$v) {
                          _vm.mode = $$v
                        },
                        expression: "mode",
                      },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "py-0",
                                        attrs: { value: _vm.modes.freeDrawing },
                                        on: { click: _vm.enableLine },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" edit ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("imageStudio.tooltips.freeDraw"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "py-0",
                                        attrs: { value: _vm.modes.circle },
                                        on: { click: _vm.enableEllipse },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(" panorama_fish_eye "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("imageStudio.tooltips.ellipse"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "py-0",
                                        attrs: { value: _vm.modes.rect },
                                        on: { click: _vm.enableRectangle },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" crop_square ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("imageStudio.tooltips.rectangle"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "py-0",
                                        attrs: { value: _vm.modes.text },
                                        on: { click: _vm.enableText },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" text_fields ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("imageStudio.tooltips.text"))),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "py-0",
                                        attrs: { value: _vm.modes.arrow },
                                        on: { click: _vm.enableArrow },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" call_made ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("imageStudio.tooltips.arrow"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "py-0",
                                        attrs: { value: _vm.modes.crop },
                                        on: { click: _vm.enableCrop },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v(" crop ")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("imageStudio.tooltips.crop"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-col"),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-center",
                                      attrs: { "min-height": "56" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: _vm.color,
                                              elevation: "0",
                                              "min-height": "48",
                                              "min-width": "48",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("v-color-picker", {
                            staticClass: "mx-auto",
                            attrs: {
                              value: "#FF0000",
                              "hide-canvas": "",
                              "hide-inputs": "",
                              swatches: _vm.swatches,
                              "show-swatches": "",
                            },
                            model: {
                              value: _vm.color,
                              callback: function ($$v) {
                                _vm.color = $$v
                              },
                              expression: "color",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            "offset-y": "",
                            "min-height": "150",
                            "min-width": "400",
                            "max-width": "450",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-center",
                                      attrs: { "min-height": "56" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              tile: "",
                                              "min-height": "48",
                                              "min-width": "48",
                                            },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("line_weight")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "divBackground" },
                            [
                              _c("span", { staticClass: "text-h5 px-2 py-4" }, [
                                _vm._v("Width"),
                              ]),
                              _c("v-slider", {
                                staticClass: "mx-auto",
                                attrs: {
                                  height: "70",
                                  "tick-labels": _vm.lineWidthLabels,
                                  min: "0",
                                  max: "2",
                                  ticks: "always",
                                  color: "secondary",
                                  "background-color": "white",
                                  "track-color": "secondary",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.lineWidth,
                                  callback: function ($$v) {
                                    _vm.lineWidth = $$v
                                  },
                                  expression: "lineWidth",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { "min-height": "56" },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              tile: "",
                                              "min-height": "48",
                                              "min-width": "48",
                                            },
                                            on: { click: _vm.undo },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("undo")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("imageStudio.tooltips.undo"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { "min-height": "56" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                tile: "",
                                "min-height": "48",
                                "min-width": "48",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.exportDialog = true
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("cloud_download")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._l(_vm.pages, function (page, index) {
                  return _c(
                    "div",
                    {
                      key:
                        page.images[0].captureId + "_" + page.images[0].frame,
                    },
                    [
                      index + 1 === _vm.currentPageIndex
                        ? _c("ImageEditorPage", {
                            ref: "editorPage" + (index + 1),
                            refInFor: true,
                            attrs: { "page-info": page, "page-index": index },
                            on: {
                              "remove-page": _vm.removePage,
                              "page-loaded": _vm.pageLoaded,
                              "commands-updated": _vm.commandsUpdated,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-pagination", {
                    attrs: { length: _vm.pages.length },
                    model: {
                      value: _vm.currentPageIndex,
                      callback: function ($$v) {
                        _vm.currentPageIndex = $$v
                      },
                      expression: "currentPageIndex",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { loading: _vm.exportLoading } },
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("imageStudio.exportDialog.title")) + " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-radio-group",
                    {
                      model: {
                        value: _vm.exportType,
                        callback: function ($$v) {
                          _vm.exportType = $$v
                        },
                        expression: "exportType",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t("imageStudio.exportDialog.jpeg"),
                          value: 0,
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t("imageStudio.exportDialog.pdf"),
                          value: 1,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { disabled: _vm.exportType !== 1 },
                      model: {
                        value: _vm.exportDirection,
                        callback: function ($$v) {
                          _vm.exportDirection = $$v
                        },
                        expression: "exportDirection",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t("imageStudio.exportDialog.vertical"),
                          value: 0,
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t("imageStudio.exportDialog.horizontal"),
                          value: 1,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.exportError
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("imageStudio.exportDialog.error")) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { disabled: _vm.exportLoading !== false },
                      on: {
                        click: function ($event) {
                          return _vm.exportImages()
                        },
                      },
                    },
                    [_vm._v(" Export ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { disabled: _vm.exportLoading !== false },
                      on: {
                        click: function ($event) {
                          _vm.exportDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }