var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 px-12" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticClass: "subHeader" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("exportCaptureDownloadPage.title")) + " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary white--text mr-2",
              on: { click: _vm.downloadFile },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("exportCaptureDownloadPage.download")) + " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }