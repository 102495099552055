var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fields.length
    ? _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "justify-center text-h5" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("accountPage.documents.title")) + " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.error
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("accountPage.documents.error")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.fields, function (field, index) {
            return _c(
              "v-row",
              { key: index },
              [
                _c(
                  "v-col",
                  [
                    _vm.accountDocs[field.id]
                      ? _c("v-file-input", {
                          attrs: {
                            label: field.description,
                            clearable: "",
                            color: "grey darken-1",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.fileChanged(field.id)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _vm.accountDocs[field.id]
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              width: "24",
                                              height: "24",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.downloadDocument(
                                                  field.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(" cloud_download "),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.accountDocs[field.id].file,
                            callback: function ($$v) {
                              _vm.$set(_vm.accountDocs[field.id], "file", $$v)
                            },
                            expression: "accountDocs[field.id].file",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }