var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.root },
    [
      _c(
        "v-row",
        { staticClass: "align-center", attrs: { "no-gutters": "" } },
        [
          !_vm.isAvatar
            ? _c(
                "v-col",
                { staticClass: "d-flex flex-row align-center" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-h6 mx-3",
                      class: _vm.$style.lowerHeader,
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("capturePage.select3D.optionsTitle")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "pt-2 mx-3",
                      attrs: { row: "", column: false },
                      model: {
                        value: _vm.renderMode,
                        callback: function ($$v) {
                          _vm.renderMode = $$v
                        },
                        expression: "renderMode",
                      },
                    },
                    _vm._l(_vm.renderModes, function (option) {
                      return _c("v-radio", {
                        key: option.id,
                        attrs: {
                          label: option.name,
                          value: option.id,
                          color: "primary",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end mr-3 py-2" },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("export")
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("capturePage.select3D.exportModel")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }