var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-2" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.showAddDialog(true)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("accountDataFields.add")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: _vm.selectedField.length === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.showAddDialog(false)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("accountDataFields.edit")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { disabled: !_vm.canDelete() },
                  on: {
                    click: function ($event) {
                      return _vm.showDeleteDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("accountDataFields.delete")) + " ")]
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.accountFields,
                  loading: _vm.loading,
                  "item-key": "id",
                  "show-select": "",
                  "single-select": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.type",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.getTypeName(item.type)) + " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.permanent",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("v-simple-checkbox", {
                            attrs: { disabled: "" },
                            model: {
                              value: item.permanent,
                              callback: function ($$v) {
                                _vm.$set(item, "permanent", $$v)
                              },
                              expression: "item.permanent",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "item.visible",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("v-simple-checkbox", {
                            attrs: { disabled: "" },
                            model: {
                              value: item.visible,
                              callback: function ($$v) {
                                _vm.$set(item, "visible", $$v)
                              },
                              expression: "item.visible",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedField,
                  callback: function ($$v) {
                    _vm.selectedField = $$v
                  },
                  expression: "selectedField",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c("ValidationObserver", {
            ref: "obs",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var invalid = ref.invalid
                  var handleSubmit = ref.handleSubmit
                  return [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "text-h6 white--text primary" },
                          [
                            _c("span", { staticClass: "text-h5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isNewField
                                      ? _vm.$t(
                                          "accountDataFields.addDialog.addTitle"
                                        )
                                      : _vm.$t(
                                          "accountDataFields.addDialog.editTitle"
                                        )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "key",
                                            rules: "required|max:45",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "error-messages":
                                                          errors,
                                                        success: valid,
                                                        label: _vm.$t(
                                                          "accountDataFields.addDialog.key"
                                                        ),
                                                        color: "secondary",
                                                      },
                                                      model: {
                                                        value: _vm.newField.key,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newField,
                                                            "key",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newField.key",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("ValidationProvider", {
                                          attrs: { rules: "required|max:100" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "accountDataFields.addDialog.description"
                                                        ),
                                                        color: "secondary",
                                                        success: valid,
                                                        "error-messages":
                                                          errors,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newField
                                                            .description,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newField,
                                                            "description",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newField.description",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "type",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items:
                                                          _vm.accountFieldTypes,
                                                        "error-messages":
                                                          errors,
                                                        "menu-props": "auto",
                                                        label: _vm.$t(
                                                          "accountDataFields.addDialog.type"
                                                        ),
                                                        "single-line": "",
                                                        disabled:
                                                          !_vm.isNewField,
                                                        success: valid,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newField.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newField,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newField.type",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: _vm.rules.tablePosition,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var valid = ref.valid
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        max: _vm.rules
                                                          .tablePosition
                                                          .max_value,
                                                        min: _vm.rules
                                                          .tablePosition
                                                          .min_value,
                                                        success: valid,
                                                        disabled:
                                                          _vm.newField.required,
                                                        "error-messages":
                                                          errors,
                                                        label: _vm.$t(
                                                          "accountDataFields.addDialog.tablePosition"
                                                        ),
                                                        color: "secondary",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newField
                                                            .table_position,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newField,
                                                            "table_position",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newField.table_position",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "my-2",
                                          attrs: {
                                            label: _vm.$t(
                                              "accountDataFields.addDialog.visible"
                                            ),
                                            color: "secondary",
                                          },
                                          model: {
                                            value: _vm.newField.visible,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newField,
                                                "visible",
                                                $$v
                                              )
                                            },
                                            expression: "newField.visible",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "my-2",
                                          attrs: {
                                            label: _vm.$t(
                                              "accountDataFields.addDialog.required"
                                            ),
                                            color: "secondary",
                                          },
                                          model: {
                                            value: _vm.newField.required,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newField,
                                                "required",
                                                $$v
                                              )
                                            },
                                            expression: "newField.required",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "secondary",
                                  disabled: invalid,
                                },
                                on: {
                                  click: function ($event) {
                                    return handleSubmit(_vm.addField)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.newField.id
                                        ? _vm.$t(
                                            "accountDataFields.addDialog.save"
                                          )
                                        : _vm.$t(
                                            "accountDataFields.addDialog.add"
                                          )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "gray darken-3" },
                                on: {
                                  click: function ($event) {
                                    _vm.dialog = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "accountDataFields.addDialog.cancel"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "unset" },
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "tabDialog" },
            [
              _c(
                "v-card-title",
                { staticClass: "text-h6 white--text primary" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("accountDataFields.deleteDialog.title")) +
                      " "
                  ),
                ]
              ),
              _c("v-card-text", { staticClass: "text-h6 my-4" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accountDataFields.deleteDialog.message")) +
                    " "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary white--text v-btn" },
                      on: { click: _vm.deleteField },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("accountDataFields.deleteDialog.submit")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "gray darken-3" },
                      on: {
                        click: function ($event) {
                          _vm.deleteDialog = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("accountDataFields.deleteDialog.cancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.addSuccessSnackbar,
            callback: function ($$v) {
              _vm.addSuccessSnackbar = $$v
            },
            expression: "addSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("accountDataFields.addDialog.success")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.addErrorSnackbar,
            callback: function ($$v) {
              _vm.addErrorSnackbar = $$v
            },
            expression: "addErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("accountDataFields.addDialog.error")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "secondary" },
          model: {
            value: _vm.deleteSuccessSnackbar,
            callback: function ($$v) {
              _vm.deleteSuccessSnackbar = $$v
            },
            expression: "deleteSuccessSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("accountDataFields.deleteDialog.success")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.deleteErrorSnackbar,
            callback: function ($$v) {
              _vm.deleteErrorSnackbar = $$v
            },
            expression: "deleteErrorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }