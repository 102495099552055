var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { id: "video-container" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 row-height" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 row-height" },
            [
              _c(
                "VideoCanvasControls",
                {
                  ref: "fullscreenFrame",
                  attrs: {
                    "current-time": _vm.currentTime,
                    duration: _vm.duration,
                    "show-advanced-controls": true,
                  },
                  on: {
                    seeked: _vm.seekVideos,
                    played: _vm.playVideos,
                    paused: _vm.pauseVideos,
                    previous: _vm.prevVideo,
                    next: function ($event) {
                      return _vm.nextVideo(true)
                    },
                  },
                },
                [
                  _c("canvas", {
                    ref: "fullscreenCanvas",
                    staticClass: "player",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "hiddenPlayer",
          attrs: { id: "video-inner-container", align: "center" },
        },
        [
          _c("v-col", { staticClass: "pa-4", attrs: { cols: "5" } }, [
            _c("video", {
              ref: "videoPlayer1",
              staticClass: "player",
              attrs: {
                width: "100%",
                height: "100%",
                preload: "auto",
                crossorigin: "use-credentials",
              },
            }),
          ]),
          _c("v-col", { staticClass: "pa-4", attrs: { cols: "5" } }, [
            _c("video", {
              ref: "videoPlayer2",
              staticClass: "player",
              attrs: {
                width: "100%",
                height: "100%",
                preload: "auto",
                crossorigin: "use-credentials",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }