var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        margin: "0",
        border: "0",
        padding: "0",
        outline: "none",
      },
    },
    [
      _vm.suportWebGL
        ? _c("canvas", {
            ref: "canvas",
            staticStyle: {
              "margin-left": "auto",
              "margin-right": "auto",
              "padding-left": "0",
              "padding-right": "0",
              display: "block",
            },
          })
        : _c(
            "div",
            [
              _vm._t("default", function () {
                return [
                  _vm._v(" Your browser does not seem to support "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#000" },
                      attrs: {
                        href: "http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation",
                      },
                    },
                    [_vm._v("WebGL")]
                  ),
                  _vm._v("."),
                  _c("br"),
                  _vm._v("' "),
                ]
              }),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }