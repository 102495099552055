var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c("v-col", [
            _c("h1", { staticClass: "subHeader" }, [
              _vm._v(" " + _vm._s(_vm.$t("accountsPage.title")) + " "),
            ]),
          ]),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-1",
                  attrs: {
                    color: "secondary",
                    fab: "",
                    dark: "",
                    right: "",
                    top: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showAddAccount = true
                    },
                  },
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "account-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.accounts,
          options: _vm.options,
          "server-items-length": _vm.total,
          loading: _vm.loading,
          "must-sort": true,
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (header) {
                    return _c(
                      "td",
                      {
                        key: header.value + item.id,
                        class: "text-xs-" + header.align,
                        on: {
                          click: function ($event) {
                            return _vm.goToAccountPage(item.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.getAccountValue(header, item)) + " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      }),
      _c("AccountData", {
        attrs: { dialog: _vm.showAddAccount },
        on: {
          close: function ($event) {
            _vm.showAddAccount = false
          },
          saved: _vm.savedAccount,
          error: _vm.savedError,
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error" },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("addAccount.error")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }