var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "login-form", attrs: { sm: "4" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "6" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex" },
                        [
                          _c("v-spacer"),
                          _c("v-img", {
                            staticClass: "flex",
                            attrs: {
                              "max-width": "539",
                              src: require("@/assets/logo_LG.png"),
                            },
                          }),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.forgotPassword
                    ? _c(
                        "v-card-text",
                        { staticClass: "pt-4" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.validLogin && _vm.submit($event)
                                },
                              },
                              model: {
                                value: _vm.validLogin,
                                callback: function ($$v) {
                                  _vm.validLogin = $$v
                                },
                                expression: "validLogin",
                              },
                            },
                            [
                              _c("v-label", [
                                _vm._v(_vm._s(_vm.$t("loginPage.username"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.nameRules,
                                  color: "grey darken-1",
                                  required: "",
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function ($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username",
                                },
                              }),
                              _c("v-label", [
                                _vm._v(_vm._s(_vm.$t("loginPage.password"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.passwordRules,
                                  type: "password",
                                  color: "grey darken-1",
                                  required: "",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _vm.error
                                  ? _c(
                                      "label",
                                      { staticClass: "error--text mb-2" },
                                      [_vm._v(" " + _vm._s(_vm.error) + " ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary white--text mr-2",
                                      attrs: { disabled: !_vm.validLogin },
                                      on: { click: _vm.submit },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("loginPage.login")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm.isCloudEnv
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "grey lighten-3 ml-2",
                                          on: {
                                            click: _vm.enableForgotPassword,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "loginPage.forgotPassword"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-card-text",
                        { staticClass: "pt-4" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.validReset &&
                                    _vm.submitPasswordReset($event)
                                },
                              },
                              model: {
                                value: _vm.validReset,
                                callback: function ($$v) {
                                  _vm.validReset = $$v
                                },
                                expression: "validReset",
                              },
                            },
                            [
                              _c("v-label", [
                                _vm._v(_vm._s(_vm.$t("loginPage.email"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.emailRules,
                                  color: "grey darken-1",
                                  required: "",
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                              _c("v-label", [
                                _vm._v(_vm._s(_vm.$t("loginPage.username"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.nameRules,
                                  color: "grey darken-1",
                                  required: "",
                                },
                                model: {
                                  value: _vm.forgotUsername,
                                  callback: function ($$v) {
                                    _vm.forgotUsername = $$v
                                  },
                                  expression: "forgotUsername",
                                },
                              }),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _vm.error
                                  ? _c(
                                      "label",
                                      { staticClass: "error--text" },
                                      [_vm._v(" " + _vm._s(_vm.error) + " ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary white--text",
                                      attrs: { disabled: !_vm.validReset },
                                      on: { click: _vm.submitPasswordReset },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("loginPage.passwordRequest")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }